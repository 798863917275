// var root_path = window.location.protocol + '//' + document.location.hostname,
var root_path = window.location.protocol + '//' + document.location.hostname + '/lorentz/',

lorentz = lorentz || {};

lorentz = {

	enviarContato: function(){

		if(validaForm('form-contato')){
			var dados = $("#form-contato").serialize()
			jQuery.ajax({
				type: "POST",
				//url: root_path+"/MVC/action/EnviarContato.php",
				data: dados,
				success: function(msg){
					var msg = $.trim(msg);
					if (msg == "ok"){
						document.getElementById('form-contato').reset();
						alert("Contato enviado com sucesso!");
					}
					if (msg == 'erro')
						alert('Ocorreu um erro, tente novamente mais tarde!');
				}
			});
		}

	}, 

	checkMobile: function() {
		if ($(window).width() < 930) {
			return true;
		} else {
			return false;
		}
	},

	goTo: function(e) {
		var speed = 1000,
			//curva = "easeInOutQuart";
			curva = "swing";

		altura = 130;
			
		if( typeof(top) === "undefined") altura = 0; //para dar a distancia inicial do menu
		$("html, body").stop().animate({"scrollTop": $(e).offset().top-altura}, speed, curva, function(){
			global_useViewPort=true;
		});
	},

	toggleMenuMobile: function() {
		$("body").toggleClass('show-menu-mobile');
		$("#hamburguer-menu-icon").toggleClass('open');
	},

	trocarImagemEmpreendimento: function(imagem){

		$(".foto-empreendimento").removeClass('ativo');
		$("#foto-empreendimento-"+imagem).addClass('ativo');

	},



}

$(window).load(function() {
	
});